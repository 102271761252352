<template>
  <div class="outer-div">
    <v-container style="position: relative;">
      <v-row class="" justify="start" >
        <v-col class="" cols="10" sm="5" md="4"> 
          <SearchBuyer 
            color="blue"
            v-if="showSearchBar"
            @selectedBuyer="setBuyer" 
          />
          <v-btn text @click="clearSearch" v-else color="blue">
            <v-icon small left>mdi-account-search</v-icon>
            <span class="custom-transform-class text-none">New search</span>
          </v-btn>
        </v-col>
        <!-- <v-col class="d-flex justify-end">
          <v-btn color="success" text class="capitalize">
            filters
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-col> -->
        <div id="page-title">My buyers</div>
        <v-avatar id="buyerAvatar" :size="avatarSize" color="light-blue">
          <v-icon :size="avatarSize" dark>mdi-account-circle</v-icon>
        </v-avatar>
      </v-row>
      
      <v-progress-circular
          class="mx-auto mt-12"
          :size="100"
          v-if="loading"
          color="primary"
          indeterminate
        ></v-progress-circular> 

      <v-row class="">
        <v-col cols="12" sm="8" offset-sm="2" >
          <transition name="fade" mode="out-in">
            <v-card v-if="buyer" outlined>
              <v-card-text>
                <v-row align="center">
                  <v-col class="text-center" cols="12" sm="4">
                    <v-avatar :size="cardAvatar" color="light-blue lighten-2">
                      <v-icon :size="cardAvatar" dark>mdi-account-circle</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col class="text-center text-sm-left" cols="12" sm="8">
                    <span class="text-subtitle-1 mr-1 font-weight-light" v-if="buyer.Title">{{buyer.Title}}</span>
                    <span class="text-h5">{{ buyer.Forename}} {{ buyer.Surname}}</span>
                    <p class="mb-0 text-subtitle-1">{{ buyer.EmailAddress }}</p>
                    <p class="mt-0 text-subtitle-1">{{ buyer.MobilePhone }}</p>
                    <v-divider class="my-3"></v-divider>

                    <p class="mb-0 font-weight-light">Buying position: 
                      <span class="font-weight-regular" v-if="buyer.BuyingPosition">{{ buyer.BuyingPosition | formatString }}</span>
                      <span v-else>n/a</span>
                    </p>
                    <p class="my-0 font-weight-light">Selling position:
                      <span class="font-weight-regular" v-if="buyer.SellingPosition">{{ buyer.SellingPosition | formatString }}</span>
                      <span v-else>n/a</span>
                    </p>
                    <span class="font-weight-light mr-5">Max price: 
                      <span class="font-weight-regular" v-if="buyer.MaxPrice">{{ buyer.MaxPrice | filterPriceGlobal }}</span>
                      <span v-else>n/a</span>
                    </span>
                    <span class="font-weight-light">Min beds: 
                      <span class="font-weight-regular" v-if="buyer.MinBeds">{{ buyer.MinBeds | filterPriceGlobal }}</span>
                      <span v-else>n/a</span>     
                    </span>
                      <br>
                    <v-btn @click="bookAppointmentDialog = true" class="mt-3 px-0" color="light-blue lighten-2" text>
                      <v-icon left small>mdi-calendar-month</v-icon>
                      Book appointment
                    </v-btn>
                    <br>
                    <v-btn @click="makeOffer" class="px-0" color="light-blue lighten-2" text>
                      <v-icon left small>mdi-file-document-edit-outline</v-icon>
                      Make offer
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </transition>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="bookAppointmentDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      :overlay="false"
      transition="dialog-transition"
      width="650"
    >
      <CreateAppointment 
        @closeCreateComponent="bookAppointmentDialog = false"
        :selectedPersonProp="selectedPersonProp"
      />
    </v-dialog>
  </div>
</template>

<script>
import SearchBuyer from '@/components/Searches/SearchBuyer.vue';
import CreateAppointment from '@/components/Appointments/CreateAppointment.vue';

export default {
  name: 'my-buyers',
  components: {
    SearchBuyer,
    CreateAppointment
  },
  data() {
    return {
      buyer: '',
      cardAvatar: 120,
      loading: false,
      showSearchBar: false,
      bookAppointmentDialog: false
    }
  },
  created() {
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user !== ''){
    if(!this.$store.state.Calendar.allDiaries) { // add all diaries to allow appointments to be assigned
      this.$store.dispatch('Calendar/listAllDiaries')
    }
  }
  else{
    this.$router.push('/');
  }
  },
  methods: {
    clearSearch() {
      this.loading = false
      this.buyer = ''
      this.showSearchBar = true
    },
    setBuyer(buyer) {
      if(buyer.OID) {
        this.loading = true
        const id = buyer.OID

        this.$store.dispatch('getBuyerDetails', id)
        .then( res => {
          this.loading = false
          this.buyer = {...res.data, OID: buyer.OID }
          this.showSearchBar = false
        })
        .catch( err => {
          console.log(err)
        })
      }
    },
    makeOffer() {
      this.$store.commit('OffersApplications/SET_SELECTED_BUYER', this.buyer)
      this.$router.push('offer')
    }
  },
  computed: {
    avatarSize() {
      if(this.$vuetify.breakpoint.sm) { return 700 }
      if(this.$vuetify.breakpoint.md) { return 750 }
      if(this.$vuetify.breakpoint.lg) { return 800 }
      if(this.$vuetify.breakpoint.xlOnly) { return 1000 }
      return 400
    },
    selectedPersonProp() {
      if(this.buyer) {
        return {
          OID: this.buyer.OID,
          Subject: `${this.buyer.Forename} ${this.buyer.Surname}`
        }
      }
      return ''
    }
  },
  filters: {
    formatString(val) {
      if(val) {
        const newString = val.replace(/([A-Z])/g, ' $1').trim()
        const toLower = newString.toLowerCase()
        const capitalFirst = toLower.charAt(0).toUpperCase()
        return capitalFirst + toLower.slice(1)
      }
    }
  } 
}
</script>

<style>
.outer-div {
  overflow-x: hidden; 
  overflow-y: hidden;
  min-height: 100vh;
}

#buyerAvatar {
  position: absolute !important;
  right: -130px;
  top: 150px;
  opacity: 0.1 !important;
}

#page-title { 
  font-size: 2.5rem;
  position: absolute;
  top: 110px;
  left: 40px;
  color: #2196F3;
  opacity: 0.1 !important; 
}

@media screen and (min-width: 768px) {
  #buyerAvatar {
    right: -150px;
  }

  #page-title { 
    font-size: 3.5rem;
    top: 150px;
    left: 30px;
  }
}

@media screen and (min-width: 1024px) {
  #page-title { 
    font-size: 4.5rem;
    top: 170px;
    left: 70px;
    /* color: red; */
  }

  #buyerAvatar {
    right: -180px;
  }
}

@media screen and (min-width: 1264px) {
  #page-title { 
    font-size: 7rem;
    top: 150px;
    left: 100px;
    /* color: green; */
  }

  #buyerAvatar {
    right: -200px;
  }
}

@media screen and (min-width: 1904px) {
  #page-title { 
    font-size: 10rem;
    top: 150px;
    left: 300px;
    /* color: red; */
  }

}
</style>